<template>
    <div>
      <h1>Whoopsie Daisy</h1>
      <p>The page you're looking for does not exist.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
  };
  </script>
  
  <style scoped>
  h1 {
    color: red;
    font-size: 3rem;
  }
  </style>