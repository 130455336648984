import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";

// testing code
const encoder = require('int-encoder');
const alphabet = "123456789-=qwertyuip[]asdfghjkl;zxcvbnm,.";
const encode = encoder.alphabet(alphabet);
const max_int = 1e6
const randInt = Math.floor(Math.random() * max_int);
// import util from "./utility.js"
let randomURLSuffix = encode(randInt)
console.log(randomURLSuffix)
console.log("testing randomURLSuffix")

createApp(App)
  .use(router)
  .mount("#app");
