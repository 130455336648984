const encoder = require('int-encoder');

const methods = {
  encodeIntAlpha: function(num, alphabet) {
    const encode = encoder.alphabet(alphabet);
    return encode.encode(num);
  },
  // e.g. randomAlpha(1e6) 
  randomAlpha: function(max_int, alphabet = "123456789-=qwertyuip[]asdfghjkl;zxcvbnm,.") {
    const randInt = Math.floor(Math.random() * max_int);
    return this.encodeIntAlpha(randInt, alphabet);
  }
};

export default {
    methods,
  };

